import React from 'react'

function Footer() {
  return (
    <footer className='footer'>
            <p className='footer__text'>Shahin Gharbi - Tous droits réversés © 2024 </p>
    </footer>
  )
}

export default Footer